import React from 'react';
import { Row, Col, Card, CardTitle, CardText, Progress } from 'reactstrap';
import { msToMinSec } from '../util';

const emptyLeg = { 'distance': 0, 'difficulty': '', 'elapsed': 0, 'pace': 0};

const Estimates = (props) => {
  const finished = props.finished;
  const currentLeg = props.leg ? props.leg : emptyLeg;
  const start = props.start;
  const now = props.now;
  const distance = parseFloat(currentLeg['distance']);
  const pace = parseFloat(currentLeg['pace']);
  const pace30 = pace - 0.5;

  const est = Math.round(distance * pace * 60000);
  const est30 = Math.round(distance * pace30 * 60000);
  const elapsed = now - start;
  const elapsedMinSec = msToMinSec(elapsed);

  const distanceCovered = finished ? distance : ((elapsed / 60000) / pace).toFixed(2);
  const distanceCovered30 = finished ? '' : ((elapsed / 60000) / pace30).toFixed(2);
  const remaining = finished ? '0:00' : msToMinSec(est - elapsed);
  const remaining30 = finished ? '' : msToMinSec(est30 - elapsed);
  const distanceCoveredPct = finished ? 100 : parseInt(distanceCovered * 100 / distance);

  return (
    <div>
    <h5>Estimates</h5>
    <Row xs="3">
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Elapsed</CardTitle>
          <CardText>{elapsedMinSec}<br/>&nbsp;</CardText>
        </Card>
      </Col>
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Distance</CardTitle>
          <CardText>{distanceCovered}<br/><small>&nbsp;<span style={{color: "red"}}>{distanceCovered30}</span>&nbsp;</small></CardText>
        </Card>
      </Col>
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Remaining</CardTitle>
          <CardText>{remaining}<br/><small>&nbsp;<span style={{color: "red"}}>{remaining30}</span>&nbsp;</small></CardText>
        </Card>
      </Col>
    </Row>
    <Progress value={distanceCoveredPct}/>
    </div>
  )
};

export default Estimates;

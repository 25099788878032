import React from 'react';
import { Row, Col, Button } from 'reactstrap';

const TopBar = (props) => {
  const legName = props.legName;

  return (
    <Row xs="3">
      <Col xs={{size: 8}}>
        <h1>{legName}</h1>
      </Col>
      <Col className="text-end">
        <Button onClick={() => {
          props.sync();
        }}>Sync</Button>
      </Col>
    </Row>
  )
};

export default TopBar;

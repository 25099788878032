import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from './Navigation';
import Home from './pages/Home';
import Times from './pages/Times';
import Settings from './pages/Settings';
import Roster from './pages/Roster';

function App() {
  return (
    <div style={{backgroundColor: '#f1f1f1'}}>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/times" element={<Times/>} />
          <Route path="/roster" element={<Roster/>} />
          <Route path="/settings" element={<Settings/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';
import { msToTime, msToMinSec} from '../util';
import TimeEditModal from './TimeEditModal';
import RunnerEditModal from './RunnerEditModal';
import { markAndRecalculateModel, updateRunnerAndRecalcutate } from '../util';

const Leg = (props) => {
  const started = props.leg['actualStart'] > 0 ? true : false;
  const done = props.leg['actualEnd'] > 0 ? true : false;
  const cumulativeMs = done ? props.leg['actualEnd'] : props.leg['predictedEnd'];
  const estimateMs = done ? props.leg['actualEnd'] - props.leg['actualStart'] : props.leg['estimate'];
  const cumulative = msToTime(cumulativeMs);
  const estimate = msToMinSec(estimateMs);
  const difficulty = props.leg.difficulty;
  const distance = props.leg.distance;
  const diffdist = distance + '|' + difficulty.split(' ').map(word => word[0]).join('');
  const pace = msToMinSec(parseInt(estimateMs / distance));
  const secondary = "table-secondary";
  const success = "table-success";

  function onSaveRunner(leg, name, pace) {
    const legIdx = leg-1;
    updateRunnerAndRecalcutate(legIdx, name, pace, props.model, props.setModel);
  }

  function onSaveTime(leg, t) {
    const legIdx = leg-1;
    markAndRecalculateModel(t, props.model, props.setModel, legIdx);
  }

  if (started && !done) {
    return (
        <tr className={success}>
          <td style={{textAlign: "right"}}>{props.leg.leg}</td>
          <td style={{textAlign: "center"}}>{diffdist}</td>
          <td style={{textAlign: "left"}}>&nbsp;{props.leg.name}&nbsp;</td>
          <td style={{textAlign: "center"}}>{estimate}<br/>({pace})</td>
          <td style={{textAlign: "right"}}>{cumulative}</td>
        </tr>
      )
  } else if (started) {
    return (
        <tr className={secondary}>
          <td style={{textAlign: "right"}}>{props.leg.leg}</td>
          <td style={{textAlign: "center"}}>{diffdist}</td>
          <td style={{textAlign: "left"}}>&nbsp;{props.leg.name}&nbsp;</td>
          <td style={{textAlign: "center"}}>{estimate}<br/>({pace})</td>
          <td style={{textAlign: "right"}}><TimeEditModal leg={props.leg} onSave={(t) => onSaveTime(props.leg.leg, t)}/></td>
        </tr>
      )
  } else {
    return (
        <tr className={secondary}>
          <td style={{textAlign: "right"}}>{props.leg.leg}</td>
          <td style={{textAlign: "center"}}>{diffdist}</td>
          <td style={{textAlign: "left"}}><RunnerEditModal leg={props.leg} onSave={(leg, name, pace) => onSaveRunner(props.leg.leg, name, pace)}/></td>
          <td style={{textAlign: "center"}}>{estimate}<br/>({pace})</td>
          <td style={{textAlign: "right"}}>{cumulative}</td>
        </tr>
      )
  }
};

export default Leg;

import React, { useState, useEffect } from 'react';
import Leg from '../components/Leg';
import { get } from 'idb-keyval';
import { Container, Table } from 'reactstrap';

const Times = (props) => {
  const [model, setModel] = useState(null);
  const [hash, setHash] = useState(0);

  useEffect(() => {
    get('hash').then((val) => {
      if (val && val !== hash) {
        get('model').then((val) => {
          if (val) {
            const parsed = JSON.parse(val);
            setModel(parsed);
          }
        })
        .catch((err) => {
          console.log('caught err', err)
        });
        setHash(val);
      }
    });
  }, [model, hash]);

  if (model) {
    return (
      <Container>
        <Table size="sm">
          <thead>
            <tr>
              <th style={{textAlign: "right"}}>#</th>
              <th style={{textAlign: "center"}}>Dist|Diff</th>
              <th style={{textAlign: "left"}}>Name</th>
              <th style={{textAlign: "center"}}>Estimate</th>
              <th style={{textAlign: "right"}}>Finish</th>
            </tr>
          </thead>
          <tbody>
            {model.map(function(leg, i) {
              return <Leg key={i} leg={leg} model={model} setModel={(m) => setModel(m)}/>
            })}
          </tbody>
        </Table>
        <div style={{height: "80px"}}/>
      </Container>
    )
  } else {
    return (
      <div>Download a race</div>
    )
  }
};

export default Times;

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from 'reactstrap';
import { msToTime } from '../util';

const TimeEditModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [time, setTime] = useState(props.leg.actualEnd);
  const [formattedTime, setFormattedTime] = useState(msToTime(props.leg.actualEnd, true));

  function onEdit() {
    if (time > 0) {
      setModalOpen(true);
    }
  }

  function add(n) {
    setTime(time + n*1000);
  }

  function save(setModalOpen) {
    setModalOpen(false);
    props.onSave(time);
  }

  function clear() {
    setTime(0);
  }

  useEffect(() => {
    setFormattedTime(time === 0 ? 'deleted' : msToTime(time, true));
  }, [time]);

  return (
    <div>
      <ButtonGroup className="d-flex">
        <Button className="btn-block mr-1 mt-1 btn-sm" color="secondary" onClick={() => onEdit()}>{msToTime(time)}</Button>
      </ButtonGroup>
      <Modal isOpen={modalOpen} centered={true}>
        <ModalHeader>Edit Time</ModalHeader>
        <ModalBody>
          <Button onClick={() => add(60)}>+1 min</Button>&nbsp;
          <Button onClick={() => add(1)}>+1 sec</Button>
          <br/>
          <br/>
          <div className="input-group input-group-lg">
            <span className="input-group-text" id="inputGroup-sizing-lg">Time</span>
            <input type="text" readOnly={true} className="form-control" aria-label="Time" aria-describedby="inputGroup-sizing-lg" value={formattedTime}/>
          </div>
          <br/>
          <Button onClick={() => add(-60)}>-1 min</Button>&nbsp;
          <Button onClick={() => add(-1)}>-1 sec</Button>&nbsp;
          <Button onClick={() => clear()}>Clear</Button>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="btn-lg" onClick={() => save(setModalOpen)}>&nbsp; OK &nbsp;</Button>
          <Button color="link" onClick={() => setModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
};

export default TimeEditModal;

import React from 'react';
import { Button } from 'reactstrap';
import { set } from 'idb-keyval';
import { recalculateModel } from '../util';

const DownloadRace = (props) => {
  function setModel(model) {
    console.log(model);
  }

  return (
    <div>
      <center>
      <br/><br/>
      <br/><br/>
      To start, download your team data
      <br/><br/>
      <Button onClick={() => {
        fetch("https://2spot.us/combined.json")
          .then(response => {
            return response.json();
          })
          .then(responseJson => {
            set('data', JSON.stringify(responseJson));
            set('downloaded', true);
            recalculateModel(responseJson['legs'], setModel);
            props.setDownloaded(true);
          });
      }}
      >Download</Button>
      </center>
      <br/><br/>
      <br/><br/>
      <br/><br/>
      <br/><br/>
    </div>
  )
};

export default DownloadRace;

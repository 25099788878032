import { set } from 'idb-keyval';

export function jhash(s) {
  let hash = 0;
  let i = 0;
  let chr = 0;
  if (s.length === 0) {
    return hash;
  }
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
}

export function msToTime(ms, includeSeconds=false) {
  const date = new Date(ms);
  let hour = date.getHours();
  let ampm = " am";
  if (hour >= 12) {
    hour = (hour > 12) ? hour - 12 : hour;
    ampm = ' pm';
  } else if (hour === 0) {
    hour = 12;
    ampm = " am";
  }

  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  if (!includeSeconds && hour === 12 && minutes === 0) {
    return (ampm === ' am') ? 'midnight' : 'noon';
  }

  if (includeSeconds && hour === 12 && minutes === 0 && seconds === 0) {
    return (ampm === ' am') ? 'midnight' : 'noon';
  }

  if (includeSeconds) {
    return hour + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds + ampm;
  } else {
    return hour + ':' + (minutes < 10 ? '0' : '') + minutes + ampm;
  }
}

export function msToMinSec(ms) {
  const prefix = ms < 0 ? '-' : '';
  const hour = parseInt(ms / 3600000)
  const min = Math.abs(parseInt(ms / 60000) - hour * 60);
  const sec = Math.abs(parseInt((ms % 60000) / 1000));
  return prefix + (hour > 0 ? hour + ':' : '') + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
}

export function recalculateModelOld(times, model, setModel) {
  if (!times || !model) {
    return;
  }
  let newModel = [];
  let cumulative = times[0];
  model.forEach((leg, i) => {
    let duration = leg['estimate'];
    if (times.length-1 > i) {
      duration = times[i+1] - times[i];
      leg['actual'] = duration;
    }
    cumulative = cumulative + duration;
    leg['actual_cumulative'] = cumulative;
    newModel.push(leg);
  });
  set('model', JSON.stringify(newModel));
  setModel(newModel);
}

export function estimateLeg(leg) {
  const distance = leg['distance'];
  const difficulty = leg['difficulty'];
  let pace = leg['pace'];
  if (difficulty === 'Easy') {
    pace = pace - 0.5;
  } else if (difficulty === 'Hard') {
    pace = pace + 0.25;
  } else if (difficulty === 'Very Hard') {
    pace = pace + 0.5;
  }
  const e = Math.round(distance * 60000 * pace);
  return e;
}

export function updateRunnerAndRecalcutate(leg, name, pace, model, setModel) {
  model[leg]['name'] = name;
  model[leg]['pace'] = pace;
  recalculateModel(model, setModel)
}

export function markAndRecalculateModel(newTime, model, setModel, index=-1) {
  if (!model || !newTime) {
    return;
  }

  let currentlyRunningLeg = index;
  if (currentlyRunningLeg < 0) {
    model.forEach((leg, i) => {
      if (leg['actualStart'] > 0) {
        currentlyRunningLeg = i; // index
      }
    });
  }
  if (currentlyRunningLeg < 0) {
    model[0]['actualStart'] = newTime;
  } else {
    model[currentlyRunningLeg]['actualEnd'] = newTime;
  }
  recalculateModel(model, setModel);
}

export function recalculateModel(model, setModel) {
  if (!model) {
    return;
  }

  let newModel = [];
  let previousLeg = null;
  model.forEach((leg, i) => {
    let newLeg = leg;
    if (previousLeg) {
      const previousLegEnded = previousLeg['actualEnd'] > 0 ? true : false;

      newLeg['actualStart'] = previousLeg['actualEnd'];
      newLeg['predictedStart'] = previousLeg['predictedEnd'];
      if (previousLegEnded) {
        newLeg['predictedStart'] = previousLeg['actualEnd'];
      }
    } else {
      // No previous leg
      if (newLeg['actualStart'] > 0) {
        newLeg['predictedStart'] = newLeg['actualStart'];
      }
    }
    if (newLeg['actualEnd'] > 0) {
      newLeg['predictedEnd'] = newLeg['actualEnd'];
    } else {
      newLeg['estimate'] = estimateLeg(newLeg);
      newLeg['predictedEnd'] = newLeg['predictedStart'] + newLeg['estimate'];
    }

    newModel.push(newLeg);
    previousLeg = newLeg;
  });
  const s = JSON.stringify(newModel);
  set('model', s);
  set('hash', jhash(s));
  setModel(newModel);
}

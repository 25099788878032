import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const UpNext = (props) => {
  if (props.legs && props.legs.length > 0) {
    return (
        <div>
        <h5>Up next...</h5>
        {props.legs.map((leg, i) => {
          return (
          <Row key={i} xs="4">
            <Col xs="auto">{leg.leg}</Col>
            <Col xs="4">{leg.name}</Col>
            <Col xs="6">{leg.distance} | {leg.difficulty}</Col>
          </Row>
          );
        })}
        <br/><br/><br/>
        </div>
      )
  } else {
    return (
        <Container>
          <br/><br/><br/>
        </Container>
      )
  }
};

export default UpNext;

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from 'reactstrap';

const MarkModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [msg, setMsg] = useState('You are about to mark...');
  const [now, setNow] = useState(0);

  function onMark() {
    setNow(new Date());
    setModalOpen(true);
  }

  function mark(setModalOpen) {
    setModalOpen(false);
    props.onFinishedLeg(now);
  }

  //doMsg(props.leg, setMsg);
  useEffect(() => {
    if (props.leg === 0) {
      setMsg('You are about to mark the start of the race.');
    } else if (props.leg === 36) {
      setMsg('You are about to mark the end of the race.');
    } else {
      setMsg('You are about to mark the end of leg ' + props.leg);
    }
  }, [props.leg]);

  if (!props.finished && props.active) {
    return (
      <div>
        <ButtonGroup className="d-flex">
          <Button className="btn-block mr-1 mt-1 btn-lg" color="primary" onClick={() => onMark()}>Mark</Button>
        </ButtonGroup>
        <Modal isOpen={modalOpen} centered={true}>
          <ModalHeader>Mark?</ModalHeader>
          <ModalBody>{msg}</ModalBody>
          <ModalFooter>
            <Button color="primary" className="btn-lg" onClick={() => mark(setModalOpen)}>&nbsp; OK &nbsp;</Button>
            <Button color="link" onClick={() => setModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  } else {
    return (
      <div/>
    )
  }
};

export default MarkModal;

import React from 'react';
import { Row, Col, Card, CardTitle, CardText } from 'reactstrap';
import { msToMinSec } from '../util';

const LegInfo = (props) => {
  const distance = props.leg ? props.leg.distance : '';
  const difficulty = props.leg ? props.leg.difficulty : '';
  const estimate = props.leg ? msToMinSec(props.leg.estimate) : '';

  return (
    <div>
    <h5>Leg Information</h5>
    <Row xs="3">
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Distance</CardTitle>
          <CardText>{distance}</CardText>
        </Card>
      </Col>
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Difficulty</CardTitle>
          <CardText><small>{difficulty}</small></CardText>
        </Card>
      </Col>
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Estimate</CardTitle>
          <CardText>{estimate}</CardText>
        </Card>
      </Col>
    </Row>
    </div>
  )
};

export default LegInfo;

import React, { useState, useEffect } from 'react';
import Runner from '../components/Runner';
import { get } from 'idb-keyval';
import { Container, Table } from 'reactstrap';

const Roster = (props) => {
  const [roster, setRoster] = useState(null);
  useEffect(() => {
    if (!roster) {
      get('data').then((val) => {
        if (val) {
          const combined = JSON.parse(val);
          setRoster(combined['team']['roster']);
        }
      })
      .catch((err) => {
        console.log('caught err', err)
      });
    }
  }, [roster]);

  if (roster) {
      return (
        <Container>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{textAlign: "center"}}>Name</th>
                <th style={{textAlign: "center"}}>Pace</th>
              </tr>
            </thead>
            <tbody>
              {roster.map(function(runner, i) {
                return <Runner key={i} runner={runner} />
              })}
            </tbody>
          </Table>
          <div style={{height: "80px"}}/>
        </Container>
      )
  } else {
    return (
      <div>Download a race</div>
    )
  }
};

export default Roster;

import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { delMany, set, get } from 'idb-keyval';
import packageJson from '../../package.json';

const Settings = (props) => {
  const [active, setActive] = useState(false);
  const [backups, setBackups] = useState([]);

  function onActive(e) {
    const val = e.target.checked;
    set('active', val);
    setActive(val);
  }

  useEffect( () => {
    get('active').then((val) => {
      const newVal = val || false;
      setActive(newVal);
    });
  }, [active]);

  return (
    <div>
      <br/><br/>
      <br/><br/>
      <Button onClick={() => {
        delMany(['data', 'model', 'times', 'team', 'downloaded', 'active', 'hash'])
          .then(() => {
            setActive(false);
          })
          .catch((err) => console.log('reset failed:', err));
        }
      }>Reset</Button>
      <br/>
      <br/>
        Are you going to be actively timing or are you just viewing?
        <br/>
        <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" id="activeSwitch" checked={active} onChange={(e) => onActive(e)}/>
        <label className="form-check-label" htmlFor="activeSwitch">Active</label>
      </div>
      <br/>
      <br/>
      <Button onClick={() => {
        fetch("https://2spot.us/backups.php")
          .then(response => {
            return response.json();
          })
          .then(responseJson => {
            setBackups(responseJson);
          });
      }}>List Backups</Button>
      <br/>
      <br/>
      <Button key="rrr.json" onClick={() => {
        let url = "https://2spot.us/data/rrr.json";
        fetch(url)
          .then(response => {
            return response.json();
          })
          .then(responseJson => {
            set('model', JSON.stringify(responseJson));
          })
      }}>rrr.json</Button>
      <br/>
      {backups.map(b => (
        <Button key={b} onClick={() => {
          let url = "https://2spot.us/data/"+b;
          fetch(url)
            .then(response => {
              return response.json();
            })
            .then(responseJson => {
              set('model', JSON.stringify(responseJson));
            })
        }}>{b}</Button>
      ))}
      <br/>
      <br/>
      Version: {packageJson.version}
    </div>
  )
};

export default Settings;

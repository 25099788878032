import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from 'reactstrap';

const RunnerEditModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState(props.leg.name);
  const [pace, setPace] = useState(props.leg.pace);

  function onEdit() {
    setModalOpen(true);
  }

  function save(setModalOpen) {
    setModalOpen(false);
    props.onSave(props.leg.leg, name, parseFloat(pace));
  }

  function onChangeName(e) {
    setName(e.target.value);
  }

  function onChangePace(e) {
    setPace(e.target.value);
  }

  return (
    <div>
      <ButtonGroup className="d-flex">
        <Button className="btn-block mr-1 mt-1 btn-sm" color="secondary" onClick={() => onEdit()}>{name}</Button>
      </ButtonGroup>
      <Modal isOpen={modalOpen} centered={true}>
        <ModalHeader>Edit Runner</ModalHeader>
        <ModalBody>
          <div className="input-group input-group-lg">
            <span className="input-group-text" id="inputGroup-sizing-lg">Name</span>
            <input type="text" className="form-control" aria-label="Name" aria-describedby="inputGroup-sizing-lg" value={name} onChange={(e) => onChangeName(e)}/>
          </div>
          <br/>
          <div className="input-group input-group-lg">
            <span className="input-group-text" id="inputGroup-sizing-lg">Pace</span>
            <input type="text" className="form-control" aria-label="Pace" aria-describedby="inputGroup-sizing-lg" value={pace} onChange={(e) => onChangePace(e)}/>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="btn-lg" onClick={() => save(setModalOpen)}>&nbsp; OK &nbsp;</Button>
          <Button color="link" onClick={() => setModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
};

export default RunnerEditModal;

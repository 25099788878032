import React from 'react';
import { Row, Col, Card, CardTitle, CardText } from 'reactstrap';
import { msToTime, msToMinSec } from '../util';

const FinishTimes = (props) => {
  if (!props.leg) {
    return null;
  }
  const currentLeg = props.leg;
  const now = props.now;
  const start = props.start;
  const vanLeg = props.vanLeg;
  const lastLeg = props.lastLeg;
  const finished = props.finished;
  const vanCumulative = vanLeg['actualEnd'] > 0 ? vanLeg['actualEnd'] : vanLeg['predictedEnd'];
  const lastCumulative = lastLeg['actualEnd'] > 0 ? lastLeg['actualEnd'] : lastLeg['predictedEnd'];

  const legFinish = msToTime(currentLeg['actualEnd'] > 0 ? currentLeg['actualEnd'] : currentLeg['predictedEnd']);
  const vanFinish = msToTime(vanCumulative);
  const vanPrediction = finished ? '' : msToMinSec(vanCumulative - now);
  const raceFinish = msToTime(lastCumulative);
  const racePrediction = msToMinSec(finished ? (lastCumulative - start) : (lastCumulative - now));
  return (
    <div>
    <h5>Finish Times</h5>
    <Row xs="3">
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Current Leg</CardTitle>
          <CardText>{legFinish}<br/><small>&nbsp;</small></CardText>
        </Card>
      </Col>
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Van</CardTitle>
          <CardText>{vanFinish}<br/><small>{vanPrediction}&nbsp;</small></CardText>
        </Card>
      </Col>
      <Col>
        <Card className="h2 text-center" style={{width: "7rem"}}>
          <CardTitle className="mb-2 text-muted h6">Race</CardTitle>
          <CardText>{raceFinish}<br/><small>{racePrediction}</small></CardText>
        </Card>
      </Col>
    </Row>
    </div>
  )
};

export default FinishTimes;

import React from 'react';

const Runner = (props) => {
  return (
      <tr>
        <td style={{textAlign: "center"}}>{props.runner.name}</td>
        <td style={{textAlign: "center"}}>{props.runner.pace}</td>
      </tr>
    )
};

export default Runner;

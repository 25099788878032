import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { get, set } from 'idb-keyval';
import MarkModal from '../components/MarkModal';
import UpNext from '../components/UpNext';
import LegInfo from '../components/LegInfo';
import Estimates from '../components/Estimates';
import FinishTimes from '../components/FinishTimes';
import DownloadRace from '../components/DownloadRace';
import TopBar from '../components/TopBar';
import { markAndRecalculateModel, jhash } from '../util.js';

const Home = (props) => {
  const [downloaded, setDownloaded] = useState(false);
  const [model, setModel] = useState(null);
  const [hash, setHash] = useState(0);
  const [ms, setMs] = useState(0);
  const [active, setActive] = useState(false);

  function onMark(t) {
    markAndRecalculateModel(t.getTime(), model, setModel);
  }

  function sync() {
    const body = active ? JSON.stringify({'model': model}) : '';
    fetch('https://2spot.us/sync.php', {method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: {'Content-type': 'application/json'},
      body: body
    }).then(response => {
      return response.json();
    }).then((data) => {
      const s = JSON.stringify(data);
      set('model', JSON.stringify(data));
      set('hash', jhash(s));
      setModel(null);
    });
  }

  function getRaceState(model) {
    if (!model) {
      return {'started': false, 'finished': false, 'running': false, 'legIdx': 0, 'vanIdx': 5}
    }
    let legIdx = 0;
    let started = false;
    let finished = false;
    let running = false;

    if (model[0]['actualStart'] === 0) {
      // fall through
    } else if (model[model.length-1]['actualEnd'] !== 0) {
      started = true;
      finished = true;
      legIdx = model.length-1;
    } else {
      started = true;
      model.forEach((leg, i) => {
        if (leg['actualStart'] !== 0) {
          legIdx = i;
        }
      });
    }
    const vanIdx = finished ? model.length-1 : Math.floor((legIdx + 6)/6)*6-1;
    return {'started': started, 'finished': finished, 'running': running, 'legIdx': legIdx, 'vanIdx': vanIdx};
  }

  useEffect(() => {
    get('active').then((val) => {
      setActive(val);
    });
    if (!downloaded) {
      get('downloaded').then((val) => {
        if (val) {
          setDownloaded(true);
        }
      });
    }
    if (!model && downloaded) {
      get('hash').then((val) => {
        if (val && val !== hash) {
          get('model').then((val) => {
            if (val) {
              const storedModel = JSON.parse(val);
              setModel(storedModel);
              setHash(val);
            } else {
              setDownloaded(false);
            }
          })
          .catch((err) => {
            console.log('caught err', err)
          });
        }
      });
    } else {
      //console.log('model:', model);
    }

    const raceState = getRaceState(model);

    let interval = null;
    if (raceState['started'] && !raceState['finished']) {
      interval = setInterval(() => {
        setMs((new Date()).getTime());
      }, 1000);
    } else if (raceState['finished']) {
      clearInterval(interval);
    } else if (model) {
      console.log('race hasnt started (or havent read times yet)');
    }
    return () => clearInterval(interval);
  }, [ms, hash, model, downloaded, active]);

  if (!model && !downloaded) {
    return (
      <DownloadRace setDownloaded={(val) => setDownloaded(val)}/>
    )
  } else if (!model) {
    return (
      <div>spinner</div>
    )
  }

  const bottomStyle = {
    marginBottom: '80px'
  };

  const raceState = getRaceState(model);
  const started = raceState['started'];
  const finished = raceState['finished'];
  const legIdx = raceState['legIdx'];
  const vanIdx = raceState['vanIdx'];
  const raceStart = started ? model[0]['actualStart'] : model[0]['predictedStart'];
  const currentLeg = model[legIdx];
  const vanLeg = model[vanIdx];
  const lastLeg = model[model.length-1];
  const legN = legIdx+1;
  const markLeg = !started ? 0 : legN;

  const legStart = finished ? model[0]['actualStart'] : (started ? currentLeg['actualStart'] : currentLeg['predictedStart']);
  const legName = finished ? 'Finished' : (legN + ' | ' + currentLeg['name']);
  const now = !started ? raceStart : (finished ? lastLeg['actualEnd'] : new Date());

  let next = [];
  if (legIdx < model.length-1) { next.push(model[legIdx+1]); }
  if (legIdx < model.length-2) { next.push(model[legIdx+2]); }
  if (legIdx < model.length-3) { next.push(model[legIdx+3]); }
  if (legIdx < model.length-4) { next.push(model[legIdx+4]); }
  if (legIdx < model.length-5) { next.push(model[legIdx+5]); }
  if (legIdx < model.length-6) { next.push(model[legIdx+6]); }

  return (
    <Container>
      <TopBar legName={legName} sync={() => sync()}/>
      <br/>
      <LegInfo leg={currentLeg}/>
      <br/>
      <Estimates leg={currentLeg} now={now} start={legStart} finished={finished}/>
      <br/>
      <FinishTimes leg={currentLeg} vanLeg={vanLeg} lastLeg={lastLeg} now={now} start={raceStart} legStart={legStart} finished={finished}/>
      <UpNext legs={next}/>
      <br/>
      <div className="fixed-bottom" style={bottomStyle}>
      <MarkModal leg={markLeg} finished={finished} active={active} onFinishedLeg={(t) => onMark(t)}/>
      </div>
    </Container>
  )
};

export default Home;
